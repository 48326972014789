<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <v-alert-error :show="isSaveError" :close="closeAlert"></v-alert-error>
    <v-alert-confirm
      :show="isDelete"
      :title="'Remove Confirmation'"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      :headline-color="'black'"
      :buttonText="'Remove'"
      :confirm="confirmDelete"
      :icon="''"
    ></v-alert-confirm>
    <v-alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></v-alert-confirm>
    <v-preview
      v-if="isPreview"
      :show="isPreview"
      :close-preview="closePreview"
      :doctor-object="doctorObject"
    />
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px;">
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Doctor
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermDelete()"
            :show-preview="true"
            :show-save="!disabledEdit"
            :disabled-preview="disabledPreview"
            :on-remove="showDeletePopup"
            :on-cancel="showCancelPopup"
            :on-preview="onPreview"
            :on-save="onSave"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            Doctor Information
          </h6>
          <v-spacer></v-spacer>
          <p class="edit-field-label sub-body-bold mr-4" t-data="status-title">
            Status
          </p>
          <v-switch
            color="primary"
            v-model="doctorObject.status"
            :disabled="disabledEdit || disableEmptyBranch"
            :false-value="1"
            :true-value="0"
            class="mb-0 pb-0"
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-0">
          <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Header Image <span class="danger500Color--text">*</span>
              </p>
              <image-dropzone
                title="Header Image"
                subtitle="Recommended size 176x176px."
                subtitle-font-class="small-bold"
                content-font-class="small"
                class="can-disable-selector"
                :thumbnail="doctorObject.profile"
                :is-error="isHeaderImageError"
                :error-msg="headerImageErrorMessage"
                :disabled="disabledEdit"
                @update="updateImageHeader"
                @remove="removeImageHeader"
              ></image-dropzone>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <single-selected-branch
                outlined
                required
                dense
                title="Branch <span class='danger500Color--text'>*</span>"
                :isDisabled="disabledEdit"
                :branch="branches"
                :selectedBranch="selectedBranch"
                :syncData="changedSelectedBranch"
                @change="resetDepartmentAndLicenseValidate"
                :isEditMode="isEditMode"
                v-if="!isLoading"
              ></single-selected-branch>
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Department <span class="danger500Color--text">*</span>
              </p>
              <v-select
                :items="departmentList"
                v-model="doctorObject.department_id"
                :disabled="disabledEdit || disableEmptyBranch"
                :menu-props="{bottom: true, offsetY: true}"
                outlined
                dense
                item-text="text"
                item-value="value"
                class="can-disable-selector"
                :placeholder="disabledEdit ? '' : 'Select Department'"
                :rules="departmentRules"
              ></v-select>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Title <span class="danger500Color--text">*</span>
              </p>
              <v-select
                :items="prefixObject"
                v-model="doctorObject.prefix_id"
                :disabled="disabledEdit || disableEmptyBranch"
                :menu-props="{bottom: true, offsetY: true}"
                outlined
                dense
                item-text="text"
                item-value="value"
                :rules="TitleRules"
                class="can-disable-selector"
                :placeholder="disabledEdit ? '' : 'Doctor Title'"
                @input="handlePrefixChange"
                :error="1 === 2 ? true : false"
              ></v-select>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                License No <span class="danger500Color--text">*</span>
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit || disableEmptyBranch"
                v-model="doctorObject.license_no"
                :error="isLicenseError"
                :rules="licenseNoRules"
                :error-messages="licenseErrorMessage"
                :placeholder="disabledEdit ? '' : 'Enter Doctor\'s License No.'"
                @input="resetLicenseValidate"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6">
              <h4 class="mb-6 card-title" t-data="info-title">
                Thai
              </h4>
            </v-col>
            <v-col cols="6">
              <h4 class="mb-6 card-title" t-data="info-title">
                English
              </h4>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Doctor Name <span class="danger500Color--text">*</span>
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit || disableEmptyBranch"
                :rules="nameThRules"
                v-model="doctorObject.contents[0].name"
                :placeholder="disabledEdit ? '' : 'Doctor Name'"
              ></v-text-field>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Doctor Name <span class="danger500Color--text">*</span>
              </p>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :disabled="disabledEdit || disableEmptyBranch"
                :rules="nameEnRules"
                v-model="doctorObject.contents[1].name"
                :placeholder="disabledEdit ? '' : 'Doctor Name'"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 px-2">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Specialty <span class="danger500Color--text">*</span>
              </p>
              <v-select
                :items="specialtyListTh"
                v-model="doctorObject.specialty_id"
                :disabled="disabledEdit || disableEmptyBranch"
                :menu-props="{bottom: true, offsetY: true}"
                outlined
                dense
                item-text="text"
                item-value="value"
                class="can-disable-selector"
                :placeholder="disabledEdit ? '' : 'Specialty'"
                :rules="specialtyRules"
              ></v-select>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Specialty <span class="danger500Color--text">*</span>
              </p>
              <v-select
                :items="specialtyListEn"
                v-model="doctorObject.specialty_id"
                :disabled="disabledEdit || disableEmptyBranch"
                :menu-props="{bottom: true, offsetY: true}"
                outlined
                dense
                item-text="text"
                item-value="value"
                class="can-disable-selector"
                :placeholder="disabledEdit ? '' : 'Specialty'"
                :rules="specialtyRules"
              ></v-select>
            </v-col>
          </v-row>

          <v-row align="start" justify="start" class="mt-0 mb-6 px-2">
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Education/Experience
              </p>
              <quill-editor
                ref="quillTh"
                v-model="doctorObject.contents[0].description"
                :options="editorOption"
              ></quill-editor
            ></v-col>
            <v-col cols="6" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Education/Experience
              </p>

              <quill-editor
                ref="quillEn"
                v-model="doctorObject.contents[1].description"
                :options="editorOption"
              ></quill-editor
            ></v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row class="d-flex align-start mr-0 mb-3" style="margin-left: 1px;">
      <created-detail
        v-if="isEditMode"
        :data-object="doctorObject"
      />
      <v-spacer></v-spacer>
      <edit-buttons
        class="mt-3"
        :show-remove="isEditMode && hasPermDelete()"
        :show-preview="true"
        :show-save="!disabledEdit"
        :disabled-preview="disabledPreview"
        :on-remove="showDeletePopup"
        :on-cancel="showCancelPopup"
        :on-preview="onPreview"
        :on-save="onSave"
      />
    </v-row>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor, Quill} from 'vue-quill-editor'
import {mapState, mapActions} from 'vuex'
// import moment from 'moment'
import PopupLoading from '../../components/PopupLoading'
import AlertError from '../../components/AlertError'
import moment from 'moment'
import Preview from '../../components/doctor/Preview'
import CreatedDetail from '../../components/CreatedDetail'
import ImageUploader from 'quill-image-uploader'
import MyLink from '../../constants/quill-link'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import ImageDropzone from '@/components/ImageDropzone'

Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)
import {
  isUrl,
  convertFileToBase64,
  convertSelectedData,
  hasPermissionsByOrgs,
} from '../../helper/helper'
import {actionEnum} from '@/constants/constants'
import AlertConfirm from '@/components/AlertConfirm'
import EditButtons from '@/components/EditButtons'

export default {
  components: {
    'v-alert-confirm': AlertConfirm,
    'v-alert-error': AlertError,
    'v-popup-loading': PopupLoading,
    'v-preview': Preview,
    CreatedDetail,
    EditButtons,
    ImageDropzone,
    SingleSelectedBranch,
    quillEditor,
  },
  name: 'index',
  data() {
    return {
      prefix_id: '',
      datePicker: false,
      isEditMode: false,
      valid: true,
      isLoad: false,
      isCancel: false,
      isPreview: false,
      title_th: '',
      title_en: '',
      image_th: '',
      image_en: '',
      isImageEngError: false,
      imageEngErrorMessage: '',
      isLicenseError: false,
      licenseErrorMessage: '',
      description_th: '',
      description_en: '',
      categoryId: '',
      statusId: '',
      isSaveError: false,
      isError: false,
      isSuccess: false,
      imageMaxSize: 3,
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['link', 'image']
          ]
        }
      },
      html: '',
      statusItems: [
        {text: 'Active', value: 0},
        {text: 'Inactive', value: 1},
        {text: 'Active Date', value: 2}
      ],
      TitleRules: [v => !!v || 'Title is required'],
      nameThRules: [v => !!v || 'Name [TH] is required'],
      nameEnRules: [v => !!v || 'Name [EN] is required'],

      categoryRules: [v => !!v || 'Category is required'],
      hospitalRules: [v => !!v || 'Branch is required'],
      departmentRules: [v => !!v || 'Department is required'],
      specialtyRules: [v => !!v || 'Specialty is required'],
      licenseNoRules: [v => !!v || 'License no. is required'],

      activeDate: [v => !!v || 'Active date is required'],
      statusRules: [v => v != '' || 'Status is required'],
      linkroute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Doctors',
          disabled: false,
          href: '/doctor-list'
        },
        {
          text: this.textMode,
          disabled: true,
          href: '/'
        }
      ],
      date: moment(new Date())
        .add(1, 'days')
        .toISOString()
        .substr(0, 10),
      subTitle:
        'Are you sure you want to remove this Doctor? This action will remove content in both Thai and English.'
    }
  },
  mounted() {
    this.setPrefix()
    if (this.disabledEdit || this.disableEmptyBranch) {
      this.disableQuill()
    } else {
      this.enableQuill()
    }
  },
  computed: {
    publicDate: {
      get() {
        return this.checkActiveDate(
          this.doctorObject.public_date,
          this.doctorObject.status
        )
      },
      set(date) {
        this.doctorObject.public_date = moment(date)
        this.datePicker = false
      }
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Doctors', links: true, to: '/doctors'},
        {text: this.textMode, links: false}
      ]
    },
    categoryItems: function() {
      let categories = this.$store.state.doctor.category.filter(function(obj) {
        return obj.text != 'All'
      })
      return categories
    },
    isActiveDate: function() {
      return this.doctorObject.status === '2' ? true : false
    },
    cachImage: function(image) {
      return `${image}?${new Date().getTime()}`
    },
    branches() {
      return this.orgObject.map(o => o.value)
    },
    selectedBranch() {
      let selectedBranch = null
      if (this.doctorObject.org_codename !== null) {
        selectedBranch = this.doctorObject.org_codename
      }
      return selectedBranch
    },
    disabledPreview() {
      return this.doctorObject.prefix_id === '' ||
        this.doctorObject.prefix_id === null ||
        this.doctorObject.department_id === '' ||
        this.doctorObject.department_id === null ||
        this.doctorObject.org_codename === null ||
        this.doctorObject.org_codename === '' ||
        this.doctorObject.profile === null ||
        this.doctorObject.profile === '' ||
        this.doctorObject.license_no === null ||
        this.doctorObject.license_no === '' ||
        this.doctorObject.contents[0].name === null ||
        this.doctorObject.contents[0].name === '' ||
        this.doctorObject.contents[1].name === null ||
        this.doctorObject.contents[1].name === '' ||
        this.doctorObject.specialty_id === ''
    },
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditDoctor', [this.doctorObject.org_codename])
      )
    },
    disableEmptyBranch() {
      if (
        !this.doctorObject.org_codename &&
        this.doctorObject.org_codename !== null
      ) {
        return true
      }
      return false
    },
    ...mapState('doctor', [
      'doctorObject',
      'isLoading',
      'orgObject',
      'departmentList',
      'prefixObject',
      'isDuplicateLicenseNo',
      'isHeaderImageError',
      'headerImageErrorMessage',
      'isDelete',
      'specialtyListTh',
      'specialtyListEn',
    ]),
    ...mapState('auth', ['user']),
    ...mapState('department', ['departmentObject'])
  },
  destroyed() {
    this.isSuccess = true
    this.resetState()
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
  },
  beforeMount() {
    this.resetState()
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(this.isEditMode)
    await this.fetchCategory()
    await this.fetchSpecialty()
    if (this.isEditMode) {
      await this.setDoctorId(this.$route.params.id)
      // scroll to top
      window.scrollTo(0, 0)
      await this.setDepartment(true)
    }
    const isViewOnly =
      this.isEditMode &&
      !hasPermissionsByOrgs('CanEditDoctor', [this.doctorObject.org_codename])
    await this.setOrgInfo(isViewOnly)
  },
  watch: {
    disableEmptyBranch() {
      if (this.disabledEdit || this.disableEmptyBranch) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    },
    disabledEdit() {
      if (this.disabledEdit || this.disableEmptyBranch) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    },
  },
  methods: {
    disableQuill() {
      this.$refs.quillTh.quill.enable(false)
      this.$refs.quillEn.quill.enable(false)
      this.$refs.quillTh.quill.root.dataset.placeholder = ""
      this.$refs.quillEn.quill.root.dataset.placeholder = ""
    },
    enableQuill() {
      this.$refs.quillTh.quill.enable(true)
      this.$refs.quillEn.quill.enable(true)
      this.$refs.quillTh.quill.root.dataset.placeholder = "Insert text here ..."
      this.$refs.quillEn.quill.root.dataset.placeholder = "Insert text here ..."
    },
    async confirmDelete() {
      const isSuccess = await this.deletePkg()
      if (isSuccess) {
        this.$router.push({
          name: 'doctor-list',
          query: {isCreateSuccess: true, snackTitle: 'removed'}
        })
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup() {
      this.setDeleteId(this.$route.params.id)
      this.setDeletePopup(true)
    },
    resetLicenseValidate() {
      this.setIsDuplicateLicenseNo(false)
      this.licenseErrorMessage = ''
      this.isLicenseError = false
    },
    resetDepartmentAndLicenseValidate() {
      this.doctorObject.department_id = ''
      this.resetLicenseValidate()
    },
    changedSelectedBranch(value) {
      if (value.length > 0) {
        this.doctorObject.org_codename = value[0]
      }
      this.setFilterHospital(this.doctorObject.org_codename)
      this.setDepartment(true)
    },
    hasPermDelete() {
      return hasPermissionsByOrgs('CanDeleteDoctor', [
        this.doctorObject.org_codename
      ])
    },
    selectDepartment() {
      this.setFilterDepartment(this.doctorObject.department_id)
    },
    publicDateChange(date) {
      this.doctorObject.public_date = date
      this.datePicker = false
    },
    checkActiveDate(date, status) {
      if (date && status === 2) {
        return moment(date)
          .add(1, 'days')
          .toISOString()
          .substr(0, 10)
      } else
        return moment(new Date())
          .add(1, 'days')
          .toISOString()
          .substr(0, 10)
    },
    onEditorThaiChange({html}) {
      this.description_th = html
    },
    onEditorEngChange({html}) {
      this.description_en = html
    },
    handleTitleThaiChange(event) {
      this.title_th = event
    },
    handleTitleEngChange(event) {
      this.title_en = event
    },
    handleCategoryChange(event) {
      this.doctorObject.category = event
    },
    handlePrefixChange(event) {
      var data = this.prefixObject.find(o => o.value === event)
      this.doctorObject.contents[0].prefix_name = data.text
      this.doctorObject.contents[1].prefix_name = data.text_en
      this.doctorObject.prefix_id = data.value
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({
        name: 'doctor-list'
      })
    },
    async onPreview() {
      await this.setDepartmentId(this.doctorObject.department_id)
      this.doctorObject.department = this.departmentObject.contents
      this.isPreview = true
    },
    async onSave() {
      if (this.validate()) {
        if (this.isEditMode) {
          this.isSuccess = await this.updatePkg({
            id: this.$route.params.id,
            pkg: this.doctorObject
          })
          if (this.isSuccess) {
            this.licenseErrorMessage = ''
            this.isLicenseError = false
            this.$refs.form.resetValidation()
            this.resetState()
            this.$router.push({
                name: 'doctor-list',
                query: {isCreateSuccess: true, snackTitle: 'edited'}
              })
              return
          } else {
            if (this.isDuplicateLicenseNo) {
              this.licenseErrorMessage = 'License No. is duplicated'
              this.isLicenseError = true
            } else {
              return (this.isSaveError = true)
            }
          }
        } else {
          this.isSuccess  = await this.create(this.doctorObject)
          if(this.isSuccess) {
            this.$refs.form.resetValidation()
            this.licenseErrorMessage = ''
            this.isLicenseError = false
            this.$router.push({
                name: 'doctor-list',
                query: {isCreateSuccess: true, snackTitle: 'created'}
              })
          } else {
            if (this.isDuplicateLicenseNo) {
              this.licenseErrorMessage = 'License No. is duplicated'
              this.isLicenseError = true
            } else {
              return (this.isSaveError = true)
            }
          }
        }
      }
    },
    removeImageHeader() {
      this.doctorObject.profile = ''
      this.setHeaderImageError({
        isError: true,
        message: 'Image Header is required'
      })
    },
    updateImageHeader(file) {
      if (file && file.name) {
        if (!String(file.type).includes('image')) {
          this.setHeaderImageError({
            isError: true,
            message: 'Only support image file!'
          })
          return
        } else if (!file || file.size > 1040000) {
          this.setHeaderImageError({
            isError: true,
            message: 'Image size should be less than 1 MB!'
          })
          return
        } else {
          this.setHeaderImage(file)
          this.setHeaderImageError({isError: false, message: ''})
          return
        }
      } else {
        this.doctorObject.profile = ''
      }
    },
    imageRule(val) {
      if (this.isEditMode) {
        if (isUrl(val)) {
          return true
        } else {
          if (val != '') {
            return true
          }
          return 'Doctor image is required'
        }
      } else {
        if (!val || val.size > 1000000) {
          return 'Doctor image size should be less than 1 MB!'
        }
        if (!val || !String(val.size).includes('image')) {
          return 'Only support image file!'
        } else if (val != '') {
          return true
        }
        return 'Doctor image is required'
      }
    },
    statusRule(value) {
      if (value != undefined) {
        return true
      } else {
        return 'Status is required'
      }
    },
    closePreview() {
      this.isPreview = false
    },
    closeAlert() {
      this.isSaveError = false
      this.isSuccess = false
    },
    imageHeaderRules(val) {
      if (this.isEditMode) {
        this.setHeaderImageError({isError: false, message: ''})
        if (isUrl(val)) {
          return
        }
        if (val != '') {
          return
        }
        this.setHeaderImageError({
          isError: true,
          message: 'Image Header is required'
        })
        return
      } else {
        if (!val || val === '') {
          this.setHeaderImageError({
            isError: true,
            message: 'Image Header is required'
          })
          return
        }
        this.setHeaderImageError({isError: false, message: ''})
        return
      }
    },
    validate() {
      this.imageHeaderRules(this.doctorObject.profile)
      if (this.$refs.form.validate() && !this.isHeaderImageError) {
        return true
      } else {
        return false
      }
    },
    syncSelectedData(data) {
      this.doctorObject.orgs = convertSelectedData(
        data,
        this.orgObject,
        this.doctorObject.orgs
      )
    },
    ...mapActions('doctor', [
      'auth',
      'create',
      'deletePkg',
      'fetchCategory',
      'fetchSpecialty',
      'resetState',
      'setDeleteId',
      'setDeletePopup',
      'setDepartment',
      'setDoctorId',
      'setEditMode',
      'setFilterDepartment',
      'setFilterHospital',
      'setHeaderImage',
      'setHeaderImageError',
      'setIsDuplicateLicenseNo',
      'setOrgInfo',
      'setPrefix',
      'setStyleImage',
      'update',
      'updatePkg',
      'upload',
    ]),
    ...mapActions('department', ['setDepartmentId'])
  }
}
</script>

<style scoped></style>
